
import React from "react";
import SiteLayout from "../web/src/components/SiteLayout";
import "@fontsource/open-sans"

const transitionDelay = 350;

//navMenuItems={menuItems} textWhite={true}

export const wrapPageElement = ({ element, props }) => {
  return <SiteLayout {...props}>{element}</SiteLayout>;
};

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition
}) => {
  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), transitionDelay);
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.setTimeout(
      () => window.scrollTo(...(savedPosition || [0, 0])),
      transitionDelay
    );
  }
  return false;
};



